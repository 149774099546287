import {useState} from 'react';
import {
  Center,
  Container,
  Heading,
  Stack,
  Image,
  Input,
  FormControl,
  Button,
  Pressable,
  Text,
  VStack,
  Link,
} from 'native-base';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../auth.js';
import {firestore} from '../firebase.js';
import {MdVisibility, MdVisibilityOff} from 'react-icons/md';

const Login = () => {
  const [organizationId, setOrganizationId] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [accountsAccess, setAccountsAccess] = useState();
  const [canInputAccountAccess, setCanInputAccountAccess] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();

  const signIn = async (username, password) => {
    if (accountsAccess.some((account) => account.username === username && account.password === password)) {
      try {
        await auth.login(organizationId, username);
      } catch (error) {
        console.error(error.message);
        throw {error: error.message};
      }
    }
    
    return;
  };

  const onKeyDownContinue = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      checkOrganizationAndProceed();
    }
  };

  const checkOrganizationAndProceed = async () => {
    try {
      const orgDoc = await firestore
        .collection('organizations')
        .doc(organizationId)
        .get();
      if (orgDoc.exists) {
        setAccountsAccess(orgDoc.data().accountsAccess);
        setCanInputAccountAccess(true);
      } else {
        setError({error: 'Invalid organization ID!'});
      }
    } catch (error) {
      console.error(error);
    }
  };

  const login = async () => {
    try {
      await signIn(username, password);
      navigate('/trips');
    } catch (e) {
      setError(e);
    }
  };

  const onKeyDown = event => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      login();
    }
  };
  return (
    <Stack
      direction={{base: 'col', md: 'col', lg: 'row'}}
      width="full"
      height="100vh"
      bg="black"
      justifyContent="center"
      space="8"
      alignItems="center">
      <VStack flex={{base: 'none', md: 'none', lg: 1}} w="full" space="4">
        <Image
          size="sm"
          w="full"
          h={{base: '32px', md: '32px', lg: '64px'}}
          resizeMode="contain"
          src="tourexpe_logo.png"
          alt="tourexpe logo"
        />
        <Image
          size="md"
          w="full"
          h={{base: '48px', md: '48px', lg: '84px'}}
          resizeMode="contain"
          src="tourexpe_console_logo.png"
          alt="tourexpe console logo"
        />
      </VStack>
      <Container
        flex={{base: 'none', md: 'none', lg: 1}}
        alignItems="center"
        justifyContent="center">
        <FormControl isInvalid={error} alignItems="center">
          <VStack
            borderRadius="lg"
            bg="coolGray.800"
            p="8"
            py="12"
            width="full"
            maxWidth="400px"
            alignItems="center"
            space="8">
            <Heading color="white">Log in</Heading>

            {!canInputAccountAccess ? (
              <VStack space="4" w="full" alignItems="center">
                <Input
                  color="white"
                  width="100%"
                  size="lg"
                  value={organizationId}
                  placeholder="Organization ID"
                  onKeyPress={onKeyDownContinue}
                  onChange={e => {
                    setError();
                    setOrganizationId(e.target.value)
                  }}
                />
                <FormControl.ErrorMessage>
                  Invalid organization ID
                </FormControl.ErrorMessage>
                <Button
                  w="full"
                  onPress={checkOrganizationAndProceed}
                  colorScheme="indigo">
                  <Text fontWeight="600" color="white">
                    Continue
                  </Text>
                </Button>

                <Text mt="8" color="muted.200" fontSize="16px">
                  Want to register? <Link isUnderlined={false} color="primary.600" href="/onboarding">Get started</Link>
                </Text>
              </VStack>
            ) : (
              <VStack space="4" w="full" alignItems="center">
                <Input
                  color="white"
                  width="100%"
                  size="lg"
                  value={username}
                  placeholder="Username"
                  onChange={e => setUsername(e.target.value)}
                />
                <Input
                  color="white"
                  width="100%"
                  size="lg"
                  value={password}
                  placeholder="Password"
                  onKeyPress={onKeyDown}
                  onChange={e => setPassword(e.target.value)}
                  type={showPassword ? 'text' : 'password'}
                  InputRightElement={
                    <Pressable
                      px="2"
                      onPress={() => setShowPassword(!showPassword)}>
                      {showPassword ? (
                        <MdVisibility color="gray" size="20" />
                      ) : (
                        <MdVisibilityOff color="gray" size="20" />
                      )}
                    </Pressable>
                  }
                />
                <FormControl.ErrorMessage>
                  Wrong username or password
                </FormControl.ErrorMessage>
                <Button w="full" size="lg" onPress={login} colorScheme="indigo">
                  <Text fontSize="lg" fontWeight="600" color="white">
                    Log in
                  </Text>
                </Button>
              </VStack>
            )}
          </VStack>
        </FormControl>
      </Container>
    </Stack>
  );
};

export default Login;
