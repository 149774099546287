import React, {useState, useMemo} from 'react';
import {VStack, HStack, Button, Text} from 'native-base';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '../../styles/dateRangePicker.css';
import {MaterialReactTable} from 'material-react-table';
import {useUser} from '../../contexts/userContext';
import {firestore} from '../../firebase';
import writeXlsxFile from 'write-excel-file';
import {FaFileExcel} from 'react-icons/fa6';
import date from 'date-and-time';

const now = new Date();
function getMonday(d) {
  d = new Date(d);
  var day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1);
  return new Date(d.setDate(diff));
}

const startOfWeek = getMonday(now);
const endOfDay = new Date(
  now.getFullYear(),
  now.getMonth(),
  now.getDate(),
  23,
  59,
  59,
  999,
);

const DEFAULT_MIN_DATE = new Date('2024-07-01');
const DEFAULT_MAX_DATE = new Date();

export default function Leads() {
  const [user] = useUser();
  const [dateRange, setDateRange] = useState([startOfWeek, endOfDay]);
  const [minDate, setMinDate] = useState(DEFAULT_MIN_DATE);
  const [maxDate, setMaxDate] = useState(DEFAULT_MAX_DATE);
  const [leadsData, setLeadsData] = useState([]);
  const [isFetchingData, setIsFetchingData] = useState(false);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'date',
        header: 'Date',
        Cell: ({cell}) => date.format(cell.getValue(), 'DD/MM/YYYY'),
      },
      {
        accessorKey: 'customerName',
        header: 'Customer Name',
      },
      {
        accessorKey: 'contactNumber',
        header: 'Contact Number',
      },
      {
        accessorKey: 'requirements',
        header: 'Requirements',
      },
      {
        accessorKey: 'employeeName',
        header: 'Employee Name',
      },
    ],
    [],
  );

  const fetchData = async () => {
    setIsFetchingData(true);
    const startDate = dateRange[0];
    const endDate = dateRange[1];

    try {
      let query = firestore
        .collection('trips')
        .where('user.organizationId', '==', user.organizationId)
        .where('status', 'in', [
          'pending_approval',
          'approved_by_moderator',
          'approved',
          'paid',
        ])
        .where('submitTime', '>=', startDate)
        .where('submitTime', '<=', endDate);

      if (['sales', 'parts', 'service'].includes(user.department)) {
        query = query.where(
          'user.department',
          '==',
          user.department.charAt(0).toUpperCase() + user.department.slice(1),
        );
      }

      const tripsSnapshot = await query.get();

      const leadsArray = [];

      tripsSnapshot.docs.forEach(doc => {
        const data = doc.data();
        if (data.leadInfo && Array.isArray(data.leadInfo)) {
          data.leadInfo.forEach(customer => {
            leadsArray.push({
              date: data.submitTime.toDate(),
              customerName: customer.name || 'N/A',
              contactNumber: customer.contactNumber || 'N/A',
              requirements: customer.requirements || 'N/A',
              employeeName: data.user.name || 'N/A',
            });
          });
        }
      });

      setLeadsData(leadsArray);
    } catch (error) {
      console.error('Error fetching leads data:', error);
    } finally {
      setIsFetchingData(false);
    }
  };

  const exportData = () => {
    const headerRow = [
      {type: String, value: 'Date', fontWeight: 'bold'},
      {type: String, value: 'Customer Name', fontWeight: 'bold'},
      {type: String, value: 'Contact Number', fontWeight: 'bold'},
      {type: String, value: 'Requirements', fontWeight: 'bold'},
      {type: String, value: 'Employee Name', fontWeight: 'bold'},
    ];

    const dataRows = leadsData.map(lead => [
      {type: String, value: date.format(lead.date, 'DD/MM/YYYY')},
      {type: String, value: lead.customerName},
      {type: String, value: lead.contactNumber},
      {type: String, value: lead.requirements},
      {type: String, value: lead.employeeName},
    ]);

    const data = [headerRow, ...dataRows];

    writeXlsxFile(data, {
      fileName: 'Leads_Report.xlsx',
      sheet: 'Leads',
      columns: [
        {width: 15}, // Date
        {width: 20}, // Customer Name
        {width: 15}, // Contact Number
        {width: 30}, // Requirements
        {width: 20}, // Employee Name
      ],
    });
  };

  const getMinDateForCalendar = (selectedDate = new Date()) => {
    setMinDate(date.addDays(selectedDate, -30));
  };

  const getMaxDateForCalendar = (selectedDate = new Date()) => {
    setMaxDate(date.addDays(selectedDate, 30));
  };

  const table = useMemo(
    () => (
      <MaterialReactTable
        columns={columns}
        data={leadsData}
        enableHiding={false}
        enableColumnFilters={false}
        enableColumnActions={false}
        enablePagination={false}
        enableStickyHeader={true}
        enableSorting={false}
        enableFullScreenToggle={false}
        enableBottomToolbar={false}
        muiTableProps={{
          sx: {
            tableLayout: 'fixed',
          },
        }}
        muiTablePaperProps={{
          elevation: 0,
          sx: {
            borderRadius: '8px',
            border: '1px solid #E0E0E0',
            overflow: 'auto',
            height: '100%',
            flex: '1',
          },
        }}
        muiTableContainerProps={{
          sx: {
            maxHeight: '500px',
          },
        }}
        muiTableBodyProps={{
          sx: {
            height: '40vh',
          },
        }}
        renderTopToolbarCustomActions={
          leadsData.length
            ? () => (
                <HStack space="2" alignItems="baseline">
                  <Text>Total leads:</Text>
                  <Text bold fontSize="20px">
                    {leadsData.length}
                  </Text>
                </HStack>
              )
            : null
        }
      />
    ),
    [columns, leadsData],
  );

  return (
    <VStack space="6">
      <HStack space="4" zIndex="2">
        <DateRangePicker
          onChange={setDateRange}
          value={dateRange}
          minDate={minDate}
          maxDate={maxDate}
          onClickDay={v => {
            getMinDateForCalendar(v);
            getMaxDateForCalendar(v);
          }}
          onCalendarOpen={() => {
            setMinDate(DEFAULT_MIN_DATE);
            setMaxDate(DEFAULT_MAX_DATE);
          }}
          format="dd/MM/yyyy"
          selectRange={true}
          clearIcon={null}
        />
        <HStack space="4">
          <Button
            onPress={fetchData}
            isLoading={isFetchingData}
            isDisabled={isFetchingData}>
            <Text fontWeight="bold" color="white">
              Get Data
            </Text>
          </Button>

          {leadsData.length > 0 && (
            <Button
              onPress={exportData}
              colorScheme="secondary"
              startIcon={<FaFileExcel color="white" size="20" />}>
              <Text fontWeight="bold" color="white">
                Export
              </Text>
            </Button>
          )}
        </HStack>
      </HStack>

      <VStack w="full" flex="1">
        {table}
      </VStack>
    </VStack>
  );
}
