import React, {useState, useEffect, useMemo} from 'react';
import {VStack, HStack, Button, Text} from 'native-base';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '../../styles/dateRangePicker.css';
import {MaterialReactTable} from 'material-react-table';
import date from 'date-and-time';
import {useUser} from '../../contexts/userContext';
import {useOrganization} from '../../contexts/OrganizationContext';
import {firestore} from '../../firebase';

const now = new Date();
function getMonday(d) {
  d = new Date(d);
  var day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1);
  return new Date(d.setDate(diff));
}

const startOfWeek = getMonday(now);
const endOfDay = new Date(
  now.getFullYear(),
  now.getMonth(),
  now.getDate(),
  23,
  59,
  59,
  999,
);

const DEFAULT_MIN_DATE = new Date('2024-07-01');
const DEFAULT_MAX_DATE = new Date();

export default function Services() {
  const [user] = useUser();
  const [organization] = useOrganization();
  const [dateRange, setDateRange] = useState([startOfWeek, endOfDay]);
  const [minDate, setMinDate] = useState(DEFAULT_MIN_DATE);
  const [maxDate, setMaxDate] = useState(DEFAULT_MAX_DATE);
  const [servicesData, setServicesData] = useState([]);
  const [serviceTypes, setServiceTypes] = useState();
  const [isFetchingData, setIsFetchingData] = useState(false);

  useEffect(() => {
    fetchServiceTypes();
  }, []);

  const fetchServiceTypes = async () => {
    setServiceTypes(organization.settings.serviceTypes);
  };

  const getMinDateForCalendar = (selectedDate = new Date()) => {
    setMinDate(date.addDays(selectedDate, -30));
  };

  const getMaxDateForCalendar = (selectedDate = new Date()) => {
    setMaxDate(date.addDays(selectedDate, 30));
  };

  const columns = useMemo(
    () => [
      {accessorKey: 'serviceType', header: 'Service Type'},
      {
        accessorKey: 'serviceCount',
        header: 'Service Count',
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableHeadCellProps: {
          align: 'center',
        },
      },
    ],
    [servicesData],
  );

  const fetchData = async () => {
    setIsFetchingData(true);

    try {
      const tripsSnapshot = await firestore
        .collection('trips')
        .where('user.department', '==', 'Service')
        .where('user.organizationId', '==', user.organizationId)
        .where('status', 'in', [
          'pending_approval',
          'approved_by_moderator',
          'approved',
          'paid',
        ])
        .where('submitTime', '>=', dateRange[0])
        .where('submitTime', '<=', dateRange[1])
        .get();

      const serviceCounts = Object.fromEntries(
        Object.keys(serviceTypes).map(type => [type, 0]),
      );

      tripsSnapshot.forEach(doc => {
        const data = doc.data();
        data.customers?.forEach(customer => {
          if (
            customer.serviceType &&
            Object.keys(serviceTypes).includes(customer.serviceType)
          ) {
            serviceCounts[customer.serviceType]++;
          }
        });
      });

      const servicesArray = Object.entries(serviceCounts).map(
        ([serviceType, serviceCount]) => ({
          serviceType: serviceTypes[serviceType],
          serviceCount,
        }),
      );

      setServicesData(servicesArray);
    } catch (error) {
      console.error('Error fetching services data:', error);
    } finally {
      setIsFetchingData(false);
    }
  };

  const table = useMemo(
    () => (
      <MaterialReactTable
        columns={columns}
        data={servicesData}
        enableHiding={false}
        enableColumnFilters={false}
        enableColumnActions={false}
        enablePagination={false}
        enableSorting={false}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        enableBottomToolbar={false}
        enableGlobalFilter={false}
        muiTableProps={{
          sx: {
            tableLayout: 'fixed',
          },
        }}
        muiTablePaperProps={{
          elevation: 0,
          sx: {
            borderRadius: '8px',
            border: '1px solid #E0E0E0',
            overflow: 'auto',
            height: '100%',
            flex: '1',
          },
        }}
        renderTopToolbarCustomActions={
          servicesData.length
            ? () => (
                <HStack space="2" alignItems="baseline">
                  <Text>Total services:</Text>
                  <Text bold fontSize="20px">
                    {servicesData.reduce(
                      (acc, curr) => acc + curr.serviceCount,
                      0,
                    )}
                  </Text>
                </HStack>
              )
            : null
        }
      />
    ),
    [columns, servicesData],
  );

  return (
    <VStack space="6">
      <HStack space="4" zIndex="2">
        <DateRangePicker
          onChange={setDateRange}
          value={dateRange}
          minDate={minDate}
          maxDate={maxDate}
          onClickDay={v => {
            getMinDateForCalendar(v);
            getMaxDateForCalendar(v);
          }}
          onCalendarOpen={() => {
            setMinDate(DEFAULT_MIN_DATE);
            setMaxDate(DEFAULT_MAX_DATE);
          }}
          format="dd/MM/yyyy"
          selectRange={true}
          clearIcon={null}
        />
        <HStack space="4">
          <Button
            onPress={fetchData}
            isLoading={isFetchingData}
            isDisabled={isFetchingData}>
            <Text fontWeight="bold" color="white">
              Get Data
            </Text>
          </Button>
        </HStack>
      </HStack>

      <VStack w="full" flex="1">
        {table}
      </VStack>
    </VStack>
  );
}
