import {useState, useEffect} from 'react';
import {
  AlertDialog,
  Box,
  HStack,
  Center,
  Container,
  Button,
  Spinner,
  Text,
  VStack,
} from 'native-base';
import {firestore, database} from '../firebase.js';
import '../styles.css';
import RouteMapModal from '../components/RouteMapModal';
import TripCard from '../components/TripCard';
import EditTripInfoModal from '../components/EditTripInfoModal';
import RejectTripModal from '../components/RejectTripModal';
import {useUser} from '../contexts/userContext';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '../styles/dateRangePicker.css';
import {BiSolidError, BiSolidSmile} from 'react-icons/bi';
import date from 'date-and-time';
import {useOrganization} from '../contexts/OrganizationContext.js';
import axios from 'axios';

const now = new Date();
const startOfDay = new Date(
  now.getFullYear(),
  now.getMonth(),
  now.getDate(),
  0,
  0,
  0,
  0,
);
const endOfDay = new Date(
  now.getFullYear(),
  now.getMonth(),
  now.getDate(),
  23,
  59,
  59,
  999,
);

const DEFAULT_MIN_DATE = new Date('2023-07-01');
const DEFAULT_MAX_DATE = new Date();

export default function Trips() {
  const [user] = useUser();
  const [organization] = useOrganization();
  const [trips, setTrips] = useState([]);
  const [expandedTrip, setExpandedTrip] = useState();
  const [tab, setTab] = useState('completed');
  const [dateRange, setDateRange] = useState([startOfDay, endOfDay]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  const [isRouteMapModalOpen, setIsRouteMapModalOpen] = useState(false);
  const [routeCoordinates, setRouteCoordinates] = useState();
  const [isEditTripInfoModalOpen, setIsEditTripInfoModalOpen] = useState(false);
  const [tripToEdit, setTripToEdit] = useState();
  const [isDeleteTripModalOpen, setIsDeleteTripModalOpen] = useState(false);
  const [tripToDelete, setTripToDelete] = useState(false);
  const [isRejectTripModalVisible, setIsRejectTripModalVisible] =
    useState(false);
  const [tripToReject, setTripToReject] = useState(false);
  const [minDate, setMinDate] = useState(DEFAULT_MIN_DATE);
  const [maxDate, setMaxDate] = useState(DEFAULT_MAX_DATE);
  const [lastTripInCursor, setLastTripInCursor] = useState();
  const [hasLoadedAllTrips, setHasLoadedAllTrips] = useState(false);
  const [isFetchingMoreTrips, setIsFetchingMoreTrips] = useState(false);
  const [removedTrips, setRemovedTrips] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    setTrips([]);
    setHasLoadedAllTrips(false);

    let options = {};
    if (tab === 'ongoing') {
      options = {
        status: ['live'],
        department: user.department,
      };
    } else if (user.isFinance && organization?.settings.isModeratorEnabled) {
      options = {
        status: ['approved_by_moderator', 'paid'],
        department: user.department,
        dateRange,
      };
    } else if (user.isFinance) {
      options = {
        status: ['approved', 'approved_by_moderator', 'paid'],
        department: user.department,
        dateRange,
      };
    } else if (user.isModerator) {
      options = {
        status: ['approved', 'approved_by_moderator', 'rejected', 'paid'],
        department: user.department,
        dateRange,
      };
    } else {
      options = {
        status: [
          'pending_approval',
          'approved',
          'approved_by_moderator',
          'rejected',
          'paid',
        ],
        department: user.department,
        dateRange,
      };
    }

    user.setTripsListener(options, tripsSetter, errorHandler);

    return () => {
      user.unsubscribeListeners();
    };
  }, [tab, dateRange]);

  const fetchMoreTrips = () => {
    setIsFetchingMoreTrips(true);

    let options = {};
    if (tab === 'ongoing') {
      options = {
        status: ['live'],
        department: user.department,
        previousLastTrip: lastTripInCursor,
      };
    } else if (user.isFinance && organization?.settings.isModeratorEnabled) {
      options = {
        status: ['approved_by_moderator', 'paid'],
        department: user.department,
        dateRange,
        previousLastTrip: lastTripInCursor,
      };
    } else if (user.isFinance) {
      options = {
        status: ['approved', 'approved_by_moderator', 'paid'],
        department: user.department,
        dateRange,
        previousLastTrip: lastTripInCursor,
      };
    } else if (user.isModerator) {
      options = {
        status: ['approved', 'approved_by_moderator', 'rejected', 'paid'],
        department: user.department,
        dateRange,
        previousLastTrip: lastTripInCursor,
      };
    } else {
      options = {
        status: [
          'pending_approval',
          'approved',
          'approved_by_moderator',
          'rejected',
          'paid',
        ],
        department: user.department,
        dateRange,
        previousLastTrip: lastTripInCursor,
      };
    }

    return user.setTripsListener(options, tripsSetter, errorHandler);
  };

  const tripsSetter = value => {
    if (value.length < 10) {
      setHasLoadedAllTrips(true);
    }

    setTrips(prevTrips => {
      const trips = {};
      prevTrips.forEach(trip => {
        if (!removedTrips.includes(trip.id)) {
          trips[trip.id] = trip;
        }
      });
      value.forEach(trip => {
        trips[trip.id] = trip;
      });
      return Object.values(trips);
    });

    if (value.length) {
      setLastTripInCursor(
        tab === 'ongoing'
          ? value[value.length - 1].startTime
          : value[value.length - 1].submitTime,
      );
      setIsFetchingMoreTrips(false);
    } else {
      setLastTripInCursor();
    }
    setIsFetchingMoreTrips(false);
    setIsLoading(false);
  };

  const errorHandler = value => {
    setError(value);
    setIsFetchingMoreTrips(false);
    setIsLoading(false);
  };

  const toggleExpandedTrip = trip => {
    console.log(trip.id);
    if (expandedTrip && expandedTrip.id === trip.id) {
      setExpandedTrip('');
    } else {
      setExpandedTrip(trip);
    }
  };

  const openRouteMapModal = async trip => {
    setIsRouteMapModalOpen(true);
    if (trip.startTime.toDate() > new Date('2024-09-18')) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_EC2_URL}/getRoute/${trip.id}`);

        if (response.data) {
          const updatedRouteCoordinates = response.data.map(element => ({
            latitude: element.coords.latitude,
            longitude: element.coords.longitude,
            heading: element.coords.heading,
            timestamp: element.timestamp,
            odometer: element.odometer,
            battery: element.battery,
          }));

          setRouteCoordinates(updatedRouteCoordinates);
        } else {
          setRouteCoordinates([]);
        }
      } catch (error) {
        console.error('Error fetching route coordinates:', error);
        setRouteCoordinates([]);
      }
    } else {
      const routeRef = database.ref(`tripsRoutes/${trip.id}/routeCoordinates`);
      const snapshot = await routeRef.once('value');
      const routeData = snapshot.val();

      if (routeData) {
        const updatedRouteCoordinates = Object.values(routeData).map(element => ({
          latitude: element.latitude,
          longitude: element.longitude,
          heading: element.heading,
          timestamp: element.timestamp,
          odometer: element.odometer,
          battery: element.battery,
        }));
        setRouteCoordinates(updatedRouteCoordinates);
      } else {
        setRouteCoordinates([]);
      }
    }
  };

  const openEditTripInfoModal = async trip => {
    setTripToEdit(trip);
    setIsEditTripInfoModalOpen(true);
  };

  const openRejectTripModal = async trip => {
    setTripToReject(trip);
    setIsRejectTripModalVisible(true);
  };

  const openDeleteTripModal = async trip => {
    setTripToDelete(trip);
    setIsDeleteTripModalOpen(true);
  };

  const deleteTrip = async () => {
    await firestore.collection('trips').doc(tripToDelete.id).delete();
    setRemovedTrips(prev => [...prev, tripToDelete.id]);
    setIsDeleteTripModalOpen(false);
  };

  const getMinDateForCalendar = (selectedDate = new Date()) => {
    setMinDate(date.addDays(selectedDate, -6));
  };

  const getMaxDateForCalendar = (selectedDate = new Date()) => {
    setMaxDate(date.addDays(selectedDate, 6));
  };

  return (
    <Center>
      <Container
        w="full"
        maxWidth="896px"
        alignItems="center"
        justifyContent="center"
        position="relative">
        <HStack
          w="full"
          bg="white"
          zIndex="1"
          borderColor="coolGray.200"
          borderBottomWidth="1"
          justifyContent="center"
          position="sticky"
          top="0"
          h="16"
          py="2">
          {!user.isFinance && (
            <Container w="full" maxWidth="896px">
              <HStack w="full" space="8" justifyContent="center" px="4">
                <Button
                  size="lg"
                  colorScheme="success"
                  flex="1"
                  borderColor="success.500"
                  variant={tab === 'ongoing' ? 'solid' : 'outline'}
                  rounded="full"
                  onPress={() => setTab('ongoing')}>
                  Ongoing
                </Button>
                <Button
                  size="lg"
                  colorScheme="success"
                  flex="1"
                  borderColor="success.500"
                  variant={tab === 'completed' ? 'solid' : 'outline'}
                  rounded="full"
                  onPress={() => setTab('completed')}>
                  Completed
                </Button>
              </HStack>
            </Container>
          )}
        </HStack>
        <VStack w="full">
          {tab === 'completed' && (
            <HStack mt="4" alignItems="center" zIndex="1" w="full">
              <DateRangePicker
                onChange={setDateRange}
                value={dateRange}
                minDate={minDate}
                maxDate={maxDate}
                onClickDay={v => {
                  getMinDateForCalendar(v);
                  getMaxDateForCalendar(v);
                }}
                onCalendarOpen={() => {
                  setMinDate(DEFAULT_MIN_DATE);
                  setMaxDate(DEFAULT_MAX_DATE);
                }}
                format="dd/MM/yyyy"
                selectRange={true}
                clearIcon={null}
              />
            </HStack>
          )}

          {error ? (
            <Box
              w="full"
              borderColor="danger.400"
              borderWidth="1"
              rounded="lg"
              h="48"
              alignItems="center"
              p="6">
              <VStack justifyContent="center" h="full" alignItems="center">
                <Text color="danger.400">
                  <BiSolidError size="64" />
                </Text>
                <Text
                  color="danger.400"
                  style={{whiteSpace: 'pre-wrap', wordBreak: 'break-word'}}
                  textAlign="center">
                  An error has occurred.
                  <br />
                  {error.message}
                </Text>
              </VStack>
            </Box>
          ) : (
            <VStack w="full" space="4">
              {isLoading ? (
                <Center w="full" h="full">
                  <Spinner size="lg" />
                </Center>
              ) : trips.length === 0 ? (
                <Box
                  mt="4"
                  w="full"
                  borderColor="coolGray.400"
                  borderWidth="1"
                  rounded="lg"
                  h="48"
                  alignItems="center"
                  p="6">
                  <VStack justifyContent="center" h="full" alignItems="center">
                    <Text color="coolGray.300">
                      <BiSolidSmile size="64" />
                    </Text>
                    <Text color="coolGray.400" textAlign="center">
                      {tab === 'completed'
                        ? 'No trips on this day.'
                        : 'No ongoing trips.'}
                    </Text>
                  </VStack>
                </Box>
              ) : (
                <VStack space="2" mt="4">
                  {trips.map(trip => {
                    return (
                      <TripCard
                        trip={trip}
                        setRemovedTrips={setRemovedTrips}
                        isExpanded={expandedTrip && trip.id === expandedTrip.id}
                        setIsExpanded={() => toggleExpandedTrip(trip)}
                        openRouteMapModal={openRouteMapModal}
                        openEditTripInfoModal={openEditTripInfoModal}
                        openDeleteTripModal={openDeleteTripModal}
                        openRejectTripModal={openRejectTripModal}
                        key={trip.id}
                      />
                    );
                  })}

                  {!hasLoadedAllTrips && (
                    <Center py="2">
                      <Button
                        isLoading={isFetchingMoreTrips}
                        onPress={fetchMoreTrips}>
                        Load more
                      </Button>
                    </Center>
                  )}
                </VStack>
              )}
            </VStack>
          )}
        </VStack>
      </Container>

      <RouteMapModal
        trip={expandedTrip}
        routeCoordinates={routeCoordinates}
        setRouteCoordinates={setRouteCoordinates}
        isRouteMapModalOpen={isRouteMapModalOpen}
        setIsRouteMapModalOpen={setIsRouteMapModalOpen}
      />

      <EditTripInfoModal
        trip={tripToEdit}
        isEditTripInfoModalOpen={isEditTripInfoModalOpen}
        setIsEditTripInfoModalOpen={setIsEditTripInfoModalOpen}
      />

      <RejectTripModal
        trip={tripToReject}
        isRejectTripModalVisible={isRejectTripModalVisible}
        setIsRejectTripModalVisible={setIsRejectTripModalVisible}
      />

      <AlertDialog
        useRNModal={true}
        isOpen={isDeleteTripModalOpen}
        onClose={() => setIsDeleteTripModalOpen(false)}>
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Confirm delete</AlertDialog.Header>
          <AlertDialog.Body>
            <Text>
              Are you sure you want to delete this trip?
              <br />
              {tripToDelete
                ? `${tripToDelete.user.name} - ${tripToDelete.getTripDate()}`
                : ''}
            </Text>
          </AlertDialog.Body>
          <HStack w="full" justifyContent="flex-end" p="4" pt="0">
            <Button onPress={deleteTrip} colorScheme="danger" minW="24">
              Delete
            </Button>
          </HStack>
        </AlertDialog.Content>
      </AlertDialog>
    </Center>
  );
}
