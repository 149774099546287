import {createContext, useContext} from 'react';
import {auth as firebaseAuth, admin} from './firebase.js';
import User from './models/User';
import {useUser} from './contexts/userContext';
import axios from 'axios';

const AuthContext = createContext(null);

export const AuthProvider = ({children}) => {
  const [user, setUser] = useUser();
  let confirmResponse = null;
  const signUp = async (phoneNumber, appVerifier) => {
    const response = await firebaseAuth.signInWithPhoneNumber(phoneNumber, appVerifier);
    confirmResponse = response;
  };

  const verifyOtp = (otp) => {
    return confirmResponse.confirm(otp);
  }

  const getAuthToken = (uid) => {
    return axios(`https://us-central1-trackea-f2596.cloudfunctions.net/getAuthToken\?uid\=${uid}`
    ).then((response) => response.data);
  }

  const login = async (organizationId, username) => {
    const token = await getAuthToken(`${username}@${organizationId}`)
    const userCredential = await firebaseAuth.signInWithCustomToken(
      token
    );

    const u = new User({
      department: userCredential.user.uid.split('@')[0],
      organizationId: userCredential.user.uid.split('@')[1],
    });
    setUser(u);
    return u;
  };

  const logout = () => {
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{user, login, logout, signUp, verifyOtp}}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
