import {useEffect, useMemo, useState, useRef} from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Link,
  Text,
  View,
  VStack,
  useTheme,
  HStack,
  Center,
  Spinner,
} from 'native-base';
import OtpInput from 'react-otp-input';
import {MdErrorOutline} from 'react-icons/md';
import readXlsxFile from 'read-excel-file';
import {MaterialReactTable, useMaterialReactTable} from 'material-react-table';
import {FaCircleInfo, FaCopy} from 'react-icons/fa6';
import {FaExternalLinkAlt} from 'react-icons/fa';
import {useAuth} from '../auth.js';
import {getAuth, RecaptchaVerifier} from 'firebase/auth';
import {firestore} from '../firebase.js';
import generateNewOrganizationId from '../utils/getNewOrganizationId';
import {useNavigate} from 'react-router-dom';
import NumericInput from 'react-numeric-input';
NumericInput.style.input.height = '32px';
NumericInput.style.btn.background = 'white';

export default function Onboarding() {
  const {colors} = useTheme();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [invalidData, setInvalidData] = useState([]);
  const phoneInputRef = useRef(null);
  const [isSendingOTP, setIsSendingOTP] = useState(false);
  const [hasSentOTP, setHasSentOTP] = useState(false);
  const [otp, setOtp] = useState('');
  const [isVerifiedOtp, setIsVerifiedOtp] = useState(false);
  const [isVerifyingOtp, setIsVerifyingOtp] = useState(false);
  const [isOnboardingAlready, setIsOnboardingAlready] = useState(false);
  const [isNumberAlreadyRegistered, setIsNumberAlreadyRegistered] =
    useState(false);
  const [organization, setOrganization] = useState({
    phoneNumber: '',
  });
  const [hasSavedAccountsAccess, setHasSavedAccountsAccess] = useState(false);
  const drop = useRef(null);
  const auth = useAuth();
  const firebaseAuth = getAuth();
  const [isDraggedOver, setIsDraggedOver] = useState(false);
  const [file, setFile] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [fileError, setFileError] = useState();
  const fileInput = useRef(null);
  const [taPerKm2, setTaPerKm2] = useState(3);
  const [taPerKm4, setTaPerKm4] = useState(6);
  const [daPerDay1, setDaPerDay1] = useState(200);
  const [daPerDay2, setDaPerDay2] = useState(250);
  const [daPerDay3, setDaPerDay3] = useState(300);

  useEffect(() => {
    if (isVerifiedOtp) {
      setOrganization(prevOrg => {
        return {
          ...prevOrg,
          departments: ['Sales', 'Service', 'Parts'],
        };
      });

      const unsubscribe = firestore
        .collection('newOrganizations')
        .doc(organization.id)
        .onSnapshot(snapshot => {
          setOrganization({
            ...snapshot.data(),
            id: organization.id,
          });
        });

      return () => unsubscribe;
    }
  }, [isVerifiedOtp]);

  const goToStep = async s => {
    if (organization.id) {
      await firestore
        .collection('newOrganizations')
        .doc(organization.id)
        .update({
          onboardingStep: s,
        });
    }
    setStep(s);
  };

  const sendOtp = async () => {
    setIsSendingOTP(true);

    try {
      const appVerifier = new RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
        },
        firebaseAuth,
      );

      await auth.signUp(organization.phoneNumber, appVerifier);
      setHasSentOTP(true);
    } catch (error) {
      console.error(error);
    }
    setIsSendingOTP(false);
  };

  const verifyOtpAndContinue = async otp => {
    setIsVerifyingOtp(true);
    try {
      await auth.verifyOtp(otp);
    } catch (error) {
      setInvalidData(['otp']);
    }

    const orgsSnapshot = await firestore
      .collection('newOrganizations')
      .where('phoneNumber', '==', organization.phoneNumber)
      .get();

    if (orgsSnapshot.docs.length) {
      if (orgsSnapshot.docs[0].data().onboardingStep) {
        setIsOnboardingAlready(true);
        setOrganization(orgsSnapshot.docs[0].data());
      } else {
        setIsNumberAlreadyRegistered(true);
      }
    } else {
      const organizationId = generateNewOrganizationId();
      const doc = firestore.collection('newOrganizations').doc(organizationId);
      await doc.set(organization);

      setOrganization(prevOrg => {
        return {
          ...prevOrg,
          id: organizationId,
        };
      });
    }

    setIsVerifyingOtp(false);
    setIsVerifiedOtp(true);
  };

  const handleDragEnter = e => {
    setIsDraggedOver(true);
    e.preventDefault();
  };

  const handleDragLeave = e => {
    setIsDraggedOver(false);
  };

  const handleDrop = e => {
    e.preventDefault();
    if (e.dataTransfer.files.length) {
      handleFile(e.dataTransfer.files[0]);
    }
  };

  const handleFileChange = e => {
    handleFile(e.target.files[0]);
  };

  const handleFile = f => {
    setIsDraggedOver(false);
    if (!f.type.includes('sheet')) {
      setFileError(
        'Invalid file format. Please make sure the that file is an .xlsx file.',
      );
      return;
    }
    if (f) {
      setFileError();
      setFile(f);
      readFile(f);
    }
  };

  const saveEmployees = () => {
    const batch = firestore.batch();
    employees.forEach(employee => {
      const docRef = firestore.collection('employees').doc();
      batch.set(docRef, {
        name: employee['Name'],
        phoneNumber: employee['Phone number'],
        department: employee['Department'],
        level: employee['Level'],
        organizationId: organization.id,
      });
    });
    return batch.commit();
  };

  useEffect(() => {
    if (!drop || !drop.current) {
      return;
    }
    drop.current.addEventListener('dragover', handleDragEnter);
    drop.current.addEventListener('dragleave', handleDragLeave);
    drop.current.addEventListener('drop', handleDrop);
    fileInput.current.addEventListener('change', handleFileChange);

    return () => {
      if (!drop || !drop.current) {
        return;
      }
      drop.current.removeEventListener('dragover', handleDragEnter);
      drop.current.removeEventListener('dragleave', handleDragLeave);
      drop.current.removeEventListener('drop', handleDrop);
      fileInput.current.removeEventListener('change', handleFileChange);
    };
  }, [file, step]);

  const isPhoneNumberValid = number => {
    const regexp = /^(\s|\S)(\d{0,9})$/;
    return regexp.test(number);
  };

  async function readFile(f) {
    try {
      const data = await readXlsxFile(f, {
        schema: {
          Name: {
            prop: 'Name',
            type: String,
            required: true,
          },
          'Phone number': {
            prop: 'Phone number',
            type: String,
            required: true,
          },
          Department: {
            prop: 'Department',
            type: String,
            required: true,
          },
          Level: {
            prop: 'Level',
            type: String,
            required: true,
          },
        },
      });

      if (
        data.rows.some(row => {
          return !['Name', 'Phone number', 'Department', 'Level'].every(prop =>
            Object.keys(row).includes(prop),
          );
        })
      ) {
        setFile(null);
        setFileError(
          'Sheet not valid. Please make sure that the file has all the mentioned columns for all the rows.',
        );
        return;
      }

      const formattedData = data.rows.map(row => {
        return {
          ...row,
          'Phone number': '+91' + row['Phone number'].trim().slice(-10),
        };
      });
      setEmployees(formattedData);
    } catch (error) {
      console.error(error);
      setFile(null);
      setFileError(
        'Invalid file format. Please make sure that the file has all the mentioned columns.',
      );
    }
  }

  const columns = useMemo(() => {
    return [
      {
        header: 'Name',
        accessorKey: 'Name',
      },
      {
        header: 'Phone number',
        accessorKey: 'Phone number',
      },
      {
        header: 'Department',
        accessorKey: 'Department',
      },
      {
        header: 'Level',
        accessorKey: 'Level',
      },
    ];
  }, []);

  const table = useMaterialReactTable({
    columns,
    data: employees,
    enableSorting: false,
    enableRowNumbers: true,
    enableHiding: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableColumnOrdering: false,
    enableColumnFilter: false,
    enablePagination: false,
    enableBottomToolbar: false,
    enableTopToolbar: false,
    enableStickyHeader: true,
    positionActionsColumn: 'first',
    positionGlobalFilter: 'left',
    initialState: {
      density: 'compact',
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: '8px',
        border: '1px solid #E0E0E0',
        overflow: 'auto',
        height: '100%',
        flex: '1',
      },
    },
    muiTableContainerProps: {sx: {maxHeight: '400px'}},
    muiTableBodyProps: {
      sx: {
        height: '40vh',
      },
    },
    enableFullScreenToggle: false,
    enableDensityToggle: false,
  });

  const finishOnboarding = async () => {
    await firestore.collection('organizations').doc(organization.id).set({
      name: organization.name,
      phoneNumber: organization.phoneNumber,
      accountsAccess: organization.accountsAccess,
      financials: organization.financials,
      settings: organization.settings,
      id: organization.id,
    });
    firestore.collection('newOrganizations').doc(organization.id).delete();
    await auth.login(organization.id, 'admin');
    navigate('/employees');
  };

  return (
    <View
      w="full"
      alignItems="center"
      h="100vh"
      style={{
        background:
          'linear-gradient(to right top, #211e56, #29256f, #312d89, #3934a4, #423cbf, #4d46cc, #5850da, #635ae7, #7169e8, #7e77e9, #8b86ea, #9894ea)',
      }}
      justifyContent="center">
      <VStack
        w={['full', step === 4 ? '1000px' : '600px']}
        maxW={step === 4 ? '1000px' : '600px'}
        space="8"
        bg="white"
        minH={['100vh', '600px']}
        py="12"
        style={{
          boxShadow:
            'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
        }}
        borderRadius={['none', 'lg']}
        overflow="hidden"
        alignItems="center">
        {isVerifyingOtp && (
          <Center
            position="absolute"
            h="full"
            w="full"
            bg="rgba(255, 255, 255, 0.5)"
            zIndex="2"
            mt="-12">
            <Spinner size="lg" color="indigo.500" />
          </Center>
        )}

        {step === 1 && (
          <VStack px={['4', '16']} w="full" flex="1" justifyContent="center">
            <VStack space="12">
              <HStack w="full" justifyContent={['center', 'left']}>
                <Box overflow="hidden" borderRadius="5">
                  <img
                    alt="Tourexpe icon"
                    src="Tourexpe icon.png"
                    height="64px"
                  />
                </Box>
              </HStack>
              <Heading textAlign={['center', 'left']}>
                Welcome!
                <br />
                Let's get started
              </Heading>
              {!hasSentOTP ? (
                <VStack space="8">
                  <FormControl isInvalid={invalidData.includes('phoneNumber')}>
                    <InputGroup w="full">
                      <InputLeftAddon children={'+91'} />
                      <Input
                        size="lg"
                        flex="1"
                        placeholder="Enter your mobile number"
                        ref={phoneInputRef}
                        onChangeText={val => {
                          setInvalidData([]);
                          setOrganization(prevOrg => {
                            return {
                              ...prevOrg,
                              phoneNumber: '+91' + val,
                            };
                          });
                        }}
                        isInvalid={invalidData.includes('phoneNumber')}
                      />
                    </InputGroup>

                    <FormControl.ErrorMessage
                      leftIcon={
                        <MdErrorOutline size="12" color={colors.error['500']} />
                      }>
                      Invalid phone number
                    </FormControl.ErrorMessage>
                  </FormControl>
                  <div id="recaptcha-container"></div>
                  <Button
                    colorScheme="indigo"
                    isLoading={isSendingOTP}
                    isLoadingText="Sending OTP"
                    onPress={() => {
                      if (!isPhoneNumberValid(phoneInputRef.current.value)) {
                        setInvalidData(val => [...val, 'phoneNumber']);
                        phoneInputRef.current.focus();
                        return;
                      }
                      sendOtp();
                    }}>
                    Send OTP & Create Account
                  </Button>
                </VStack>
              ) : !isVerifiedOtp ? (
                <VStack space="4" w="full" alignItems={['center', 'left']}>
                  <Text textAlign={['center', 'left']}>
                    OTP sent to <Text bold>{organization.phoneNumber}</Text>.{' '}
                    <Link
                      isUnderlined={false}
                      color="indigo.600"
                      onPress={() => {
                        setHasSentOTP(false);
                        setOtp('');
                      }}>
                      Change number?
                    </Link>
                    <br />
                    Please enter the OTP to continue.
                  </Text>
                  <OtpInput
                    value={otp}
                    onChange={val => {
                      setInvalidData([]);
                      setOtp(val);
                      if (val.length === 6) {
                        verifyOtpAndContinue(val);
                      }
                    }}
                    containerStyle={{
                      width: '100%',
                      justifyContent: ['center', 'left'],
                    }}
                    inputStyle={{
                      width: '48px',
                      height: '48px',
                      fontSize: '24px',
                      borderRadius: '4px',
                      border: `1px solid ${colors.muted['300']}`,
                      outlineColor: colors.indigo['300'],
                    }}
                    renderSeparator={<Box width="8px"> </Box>}
                    numInputs="6"
                    shouldAutoFocus
                    renderInput={props => <input {...props} />}
                  />
                  <FormControl isInvalid={invalidData.includes('otp')}>
                    <FormControl.ErrorMessage
                      leftIcon={
                        <MdErrorOutline size="12" color={colors.error['500']} />
                      }>
                      Invalid OTP
                    </FormControl.ErrorMessage>
                  </FormControl>
                  <Text color="muted.600">
                    Didn't receive the OTP?{' '}
                    <Link isUnderlined={false} color="indigo.600">
                      Resend
                    </Link>
                  </Text>
                </VStack>
              ) : (
                <VStack>
                  {!isOnboardingAlready && isNumberAlreadyRegistered ? (
                    <VStack space="4">
                      <Text>
                        You have already registered this number for a different
                        organization.
                        <br />
                        Please try with a different number.
                      </Text>
                      <Button
                        size="lg"
                        colorScheme="indigo"
                        onPress={() => {
                          setHasSentOTP(false);
                          setIsVerifiedOtp(false);
                          setIsNumberAlreadyRegistered(false);
                          setOtp();
                        }}>
                        Try again
                      </Button>
                    </VStack>
                  ) : (
                    <Button
                      size="lg"
                      colorScheme="indigo"
                      onPress={() =>
                        goToStep(organization.onboardingStep || 2)
                      }>
                      Continue to set up Organization
                    </Button>
                  )}
                </VStack>
              )}
            </VStack>

            <Box alignItems="center" p="4">
              <Text color="muted.400" textAlign="center">
                By continuing you agree to our{' '}
                <Link color="indigo.400" href="/privacy">
                  privacy policy
                </Link>{' '}
                and{' '}
                <Link color="indigo.400" href="/legal">
                  terms of service
                </Link>
                .
              </Text>
            </Box>
          </VStack>
        )}

        {step === 2 && (
          <VStack
            space="8"
            px={['4', '16']}
            w="full"
            flex="1"
            justifyContent="center">
            <Heading textAlign={['center', 'left']}>
              Organization Details
            </Heading>

            <VStack space="4">
              <FormControl isInvalid={invalidData.includes('orgName')}>
                <VStack space="1">
                  <FormControl.Label>Organization name</FormControl.Label>
                  <Input
                    placeholder="Organization name"
                    onChangeText={val => {
                      setInvalidData([]);
                      setOrganization(prevOrg => {
                        return {
                          ...prevOrg,
                          name: val,
                        };
                      });
                    }}
                    isInvalid={invalidData.includes('orgName')}
                    colorScheme="indigo"
                    autoFocus
                  />
                  <FormControl.ErrorMessage
                    leftIcon={
                      <MdErrorOutline size="12" color={colors.error['500']} />
                    }>
                    Invalid organization name
                  </FormControl.ErrorMessage>
                </VStack>
              </FormControl>
              <FormControl isInvalid={invalidData.includes('departments')}>
                <VStack space="1">
                  <FormControl.Label>
                    Departments (Select at least one)
                  </FormControl.Label>
                  <Checkbox.Group
                    accessibilityLabel="Select departments"
                    defaultValue={organization.departments}
                    onChange={values => {
                      setInvalidData([]);
                      setOrganization(prevOrg => {
                        return {
                          ...prevOrg,
                          departments: values,
                        };
                      });
                    }}>
                    <Checkbox value="Sales" colorScheme="indigo" boxSize="4">
                      <Text color="muted.500">Sales</Text>
                    </Checkbox>
                    <Checkbox value="Service" colorScheme="indigo" boxSize="4">
                      <Text color="muted.500">Service</Text>
                    </Checkbox>
                    <Checkbox value="Parts" colorScheme="indigo" boxSize="4">
                      <Text color="muted.500">Parts</Text>
                    </Checkbox>
                  </Checkbox.Group>
                  <FormControl.ErrorMessage
                    leftIcon={
                      <MdErrorOutline size="12" color={colors.error['500']} />
                    }>
                    Select at least one department
                  </FormControl.ErrorMessage>
                </VStack>
              </FormControl>
            </VStack>

            <Button
              colorScheme="indigo"
              onPress={async () => {
                let isInvalid = false;
                if (!organization.name || organization.name.trim().length < 3) {
                  setInvalidData(val => [...val, 'orgName']);
                  isInvalid = true;
                }
                if (
                  !organization.departments ||
                  organization.departments.length === 0
                ) {
                  setInvalidData(val => [...val, 'departments']);
                  isInvalid = true;
                }
                if (isInvalid) {
                  return;
                }
                setInvalidData([]);

                await firestore
                  .collection('newOrganizations')
                  .doc(organization.id)
                  .update(organization);
                goToStep(3);
              }}>
              Continue
            </Button>
          </VStack>
        )}

        {step === 3 && (
          <VStack
            space="8"
            px={['4', '16']}
            w="full"
            flex="1"
            justifyContent="center">
            <Heading textAlign={['center', 'left']}>Accounts Access</Heading>
            {organization.accountsAccess ? (
              <VStack space="4">
                <Text>
                  Organization ID:{' '}
                  <Text bold letterSpacing="2">
                    {organization.id}
                  </Text>
                </Text>
                <Text>
                  Save the following usernames and passwords securely and share
                  them with the respective department heads for their login
                  access.
                </Text>
                {organization.accountsAccess.map((account, i) => {
                  return (
                    <HStack
                      bg="warmGray.100"
                      w="full"
                      p="4"
                      key={i}
                      position="relative">
                      <Text fontFamily="monospace">
                        Username: {account.username}
                        <br />
                        Password: {account.password}
                      </Text>
                      <IconButton
                        position="absolute"
                        colorScheme="secondary"
                        color="muted.500"
                        top="0"
                        right="0"
                        icon={<FaCopy size="16" />}
                        onPress={() => {
                          navigator.clipboard.writeText(
                            `Username: ${account.username}\nPassword: ${account.password}`,
                          );
                        }}
                      />
                    </HStack>
                  );
                })}
                <Checkbox
                  onChange={setHasSavedAccountsAccess}
                  colorScheme="indigo"
                  boxSize="4">
                  <Text color="muted.500">
                    I have saved all the usernames and passwords securely
                  </Text>
                </Checkbox>
              </VStack>
            ) : (
              <Center flex="1" w="full">
                <Spinner size="lg" color="indigo.500" />
              </Center>
            )}

            <Button
              colorScheme="indigo"
              isDisabled={!hasSavedAccountsAccess}
              onPress={() => {
                goToStep(4);
              }}>
              Continue
            </Button>
          </VStack>
        )}

        {step === 4 && (
          <VStack
            space="8"
            px={['4', '16']}
            w="full"
            flex="1"
            justifyContent="center">
            <Heading textAlign={['center', 'left']}>Add your employees</Heading>
            <VStack space="4">
              <Text>
                Create an excel sheet of your employees with the following
                columns only:
                <br />
                <Text bold>Name, Phone number, Department, Level</Text>
              </Text>
              {employees && employees.length ? (
                <VStack space="4">
                  <HStack space="2" alignItems="baseline">
                    <Text color="muted.600">{file.name}</Text>
                    <Button
                      size="sm"
                      colorScheme="secondary"
                      onPress={() => {
                        setFile(null);
                        setEmployees([]);
                      }}>
                      Re-upload
                    </Button>
                  </HStack>
                  <MaterialReactTable table={table} />
                </VStack>
              ) : (
                <VStack space="4">
                  <Text>
                    <Link
                      color="indigo.500"
                      isUnderlined="false"
                      href="./assets/Employees.xlsx">
                      Click here
                    </Link>{' '}
                    to download an example sheet.
                  </Text>
                  <Center
                    w="full"
                    h="150px"
                    ref={drop}
                    zIndex="10"
                    borderWidth="3"
                    borderColor={isDraggedOver ? 'indigo.400' : 'gray.300'}
                    borderRadius="xl"
                    borderStyle="dashed"
                    bg={isDraggedOver ? 'indigo.50' : 'transparent'}>
                    {fileError && <Text color="red.500">{fileError}</Text>}
                    <Text textAlign="center">
                      Drop the employees excel sheet file here
                      <br />
                      or
                    </Text>
                    <input
                      ref={fileInput}
                      type="file"
                      accept=".xlsx,.xls"
                      hidden
                    />
                    <Button
                      colorScheme="indigo"
                      variant="subtle"
                      onPress={() => {
                        fileInput.current.click();
                      }}>
                      Upload Excel
                    </Button>
                  </Center>
                </VStack>
              )}
            </VStack>

            <HStack justifyContent="space-between" alignItems="baseline">
              <Text color="muted.600">
                <FaCircleInfo /> You can add more employees later
              </Text>
              {employees.length > 0 && (
                <Button
                  colorScheme="indigo"
                  px="4"
                  onPress={() => {
                    saveEmployees();
                    goToStep(5);
                  }}>
                  <Text color="white">
                    Proceed with
                    <Text bold> {employees.length}</Text> employees
                  </Text>
                </Button>
              )}
            </HStack>
          </VStack>
        )}

        {step === 5 && (
          <VStack
            space="8"
            px={['4', '16']}
            w="full"
            flex="1"
            justifyContent="center">
            <Heading textAlign={['center', 'left']}>Financials</Heading>
            <Text>
              Customize your allowance rates.
              <br />
              (You will be able to customize it later as well)
            </Text>
            <VStack space="4">
              <Text color="muted.600">
                Travel allowance (T.A.) per kilometer
              </Text>

              <FormControl>
                <VStack space="4">
                  <HStack space="4" alignItems="baseline">
                    <FormControl.Label>2-wheeler</FormControl.Label>
                    <NumericInput
                      min={1}
                      max={10}
                      value={taPerKm2}
                      onChange={setTaPerKm2}
                    />
                  </HStack>

                  <HStack space="4" alignItems="baseline">
                    <FormControl.Label>4-wheeler</FormControl.Label>
                    <NumericInput
                      min={1}
                      max={10}
                      value={taPerKm4}
                      onChange={setTaPerKm4}
                    />
                  </HStack>
                </VStack>
              </FormControl>
            </VStack>

            <VStack space="4">
              <Text color="muted.600">Dearness allowance (D.A.) per day</Text>

              <FormControl>
                <VStack space="4">
                  <HStack space="4" alignItems="baseline">
                    <FormControl.Label>Level 1</FormControl.Label>
                    <NumericInput
                      min={1}
                      max={1000}
                      step={50}
                      value={daPerDay1}
                      onChange={setDaPerDay1}
                    />
                  </HStack>

                  <HStack space="4" alignItems="baseline">
                    <FormControl.Label>Level 2</FormControl.Label>
                    <NumericInput
                      min={1}
                      max={1000}
                      step={50}
                      value={daPerDay2}
                      onChange={setDaPerDay2}
                    />
                  </HStack>

                  <HStack space="4" alignItems="baseline">
                    <FormControl.Label>Level 3</FormControl.Label>
                    <NumericInput
                      min={1}
                      max={1000}
                      step={50}
                      value={daPerDay3}
                      onChange={setDaPerDay3}
                    />
                  </HStack>
                </VStack>
              </FormControl>
            </VStack>

            <Button
              colorScheme="indigo"
              onPress={() => {
                firestore
                  .collection('newOrganizations')
                  .doc(organization.id)
                  .update({
                    financials: {
                      TA_PER_KM: {
                        2: taPerKm2,
                        4: taPerKm4,
                      },
                      DA_PER_DAY: {
                        1: daPerDay1,
                        2: daPerDay2,
                        3: daPerDay3,
                      },
                    },
                  });
                goToStep(6);
              }}>
              Continue
            </Button>
          </VStack>
        )}

        {step === 6 && (
          <VStack
            space="8"
            px={['4', '16']}
            w="full"
            flex="1"
            justifyContent="center">
            <Heading textAlign={['center', 'left']}>Time to Launch 🚀</Heading>
            <VStack space="4">
              <Text>
                <Text bold>{organization.name}</Text> is now on boarded with
                Tourexpe successfully!
              </Text>

              <Text>
                Your organization ID is{' '}
                <Text bold letterSpacing="2">
                  {organization.id}
                </Text>
                .<br />
                You and your team will need this for signing in.
                <br />
                Share the download link for <Text bold>Fieldmate</Text> with all
                your field employees.
              </Text>
              <VStack space="4" alignItems="center">
                <img
                  alt="QR Code"
                  src="./assets/qr_code.png"
                  height="300px"
                  style={{
                    objectFit: 'contain',
                  }}
                />
                <HStack space="4" w="full">
                  <Text
                    bg="primary.50"
                    px="4"
                    paddingRight="8"
                    py="2"
                    isTruncated
                    borderRadius="4"
                    position="relative"
                    bold
                    color="primary.500">
                    https://play.google.com/store/apps/details?id=com.trackea
                    <IconButton
                      position="absolute"
                      top="0"
                      right="0"
                      icon={<FaCopy size="16" />}
                      onPress={() => {
                        navigator.clipboard.writeText(
                          'https://play.google.com/store/apps/details?id=com.trackea',
                        );
                      }}
                    />
                  </Text>
                  <Box>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.trackea"
                      target="_blank">
                      <img
                        alt="Google play badge"
                        src="./assets/googleplay_badge.png"
                        height="50px"
                        style={{
                          objectFit: 'contain',
                        }}
                      />
                    </a>
                  </Box>
                </HStack>
              </VStack>
            </VStack>

            <HStack justifyContent="end" alignItems="baseline" space="4">
              <Button
                colorScheme="secondary"
                px="4"
                onPress={() => {}}
                rightIcon={<FaExternalLinkAlt size="12" color="white" />}>
                Learn more
              </Button>
              <Button colorScheme="indigo" px="4" onPress={finishOnboarding}>
                <Text color="white" bold>
                  Go to Console dashboard
                </Text>
              </Button>
            </HStack>
          </VStack>
        )}
      </VStack>
    </View>
  );
}
