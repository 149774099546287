import {useEffect, useMemo, useState} from 'react';
import {
  AlertDialog,
  Button,
  HStack,
  Select,
  Text,
  View,
  VStack,
} from 'native-base';
import {useUser} from '../../contexts/userContext';
import getTripsData from '../../utils/getTripsData';
import getExcelColumnName from '../../utils/getExcelColumnName';
import date from 'date-and-time';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '../../styles/dateRangePicker.css';
import {FaFileExcel} from 'react-icons/fa6';
import {useOrganization} from '../../contexts/OrganizationContext';
import writeXlsxFile from 'write-excel-file';
import {MaterialReactTable, useMaterialReactTable} from 'material-react-table';

const now = new Date();
function getMonday(d) {
  d = new Date(d);
  var day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1);
  return new Date(d.setDate(diff));
}

const startOfWeek = getMonday(now);
const endOfDay = new Date(
  now.getFullYear(),
  now.getMonth(),
  now.getDate(),
  23,
  59,
  59,
  999,
);

const DEFAULT_MIN_DATE = new Date('2023-07-01');
const DEFAULT_MAX_DATE = new Date();

export default function Financial() {
  const [user] = useUser();
  const [organization] = useOrganization();
  const [dateRange, setDateRange] = useState([startOfWeek, endOfDay]);
  const [minDate, setMinDate] = useState(DEFAULT_MIN_DATE);
  const [maxDate, setMaxDate] = useState(DEFAULT_MAX_DATE);
  const [department, setDepartment] = useState();
  const [type, setType] = useState();
  const [isFetchingData, setIsFetchingData] = useState();
  const [isNoTripsModalOpen, setIsNoTripsModalOpen] = useState(false);
  const [tripsData, setTripsData] = useState();
  const columns = useMemo(() => {
    const datesArray = [];
    for (
      let i = new Date(dateRange[0].getTime());
      i <= dateRange[1];
      i.setDate(i.getDate() + 1)
    ) {
      datesArray.push(date.format(new Date(i), 'DD-MM-YYYY'));
    }
    return [
      {
        header: 'Name',
        accessorKey: 'name',
        enableColumnOrdering: true,
        muiTableBodyCellProps: {
          sx: {
            border: `1px solid #f0f0f0`,
            fontWeight: 'bold',
          },
        },
      },
      {
        accessorKey: 'total',
        header: 'Total',
        muiTableBodyCellProps: {
          align: 'right',
          sx: {
            border: `1px solid #f0f0f0`,
            fontWeight: 'bold',
          },
        },
        size: 40,
        Cell: ({row}) => {
          const amounts = Object.values(row.original).filter(
            value => typeof value === 'number',
          );
          const total = amounts.reduce((sum, value) => sum + value, 0);
          return total;
        },
        sortingFn: (rowA, rowB) => {
          const amountsA = Object.values(rowA.original).filter(
            value => typeof value === 'number',
          );
          const totalA = amountsA.reduce((sum, value) => sum + value, 0);
          const amountsB = Object.values(rowB.original).filter(
            value => typeof value === 'number',
          );
          const totalB = amountsB.reduce((sum, value) => sum + value, 0);
          return totalA - totalB;
        },
      },
      ...datesArray.map(date => {
        return {
          header: date,
          accessorKey: date,
          muiTableBodyCellProps: {
            align: 'right',
            sx: {
              border: `1px solid #f0f0f0`,
            },
          },
          size: 40,
          Cell: ({cell}) => (cell.getValue() ? cell.getValue() : 0),
          enableSorting: false,
        };
      }),
    ];
  }, [tripsData]);

  const table = useMaterialReactTable({
    columns,
    data: tripsData ? Object.values(tripsData) : [],
    enableHiding: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableColumnOrdering: false,
    enableColumnFilter: false,
    enablePagination: false,
    enableStickyHeader: true,
    enableBottomToolbar: false,
    positionActionsColumn: 'first',
    positionGlobalFilter: 'left',
    state: {
      columnPinning: {left: ['name']},
    },
    initialState: {
      density: 'compact',
      sorting: [{id: 'name', desc: false}],
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: '8px',
        border: '1px solid #E0E0E0',
        overflow: 'auto',
        height: '100%',
        flex: '1',
      },
    },
    muiTableContainerProps: {sx: {maxHeight: '500px'}},
    muiTableBodyProps: {
      sx: {
        height: '40vh',
      },
    },
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    renderTopToolbarCustomActions: tripsData
      ? ({table}) => (
          <HStack space="2" alignItems="baseline">
            <Text>Total:</Text>
            <Text bold fontSize="20px">
              ₹{totalOfAllTotals}
            </Text>
          </HStack>
        )
      : null,
  });

  useEffect(() => {
    if (!(user.isAdmin || user.isFinance || user.isModerator)) {
      if (user.department === 'sales') {
        setDepartment('Sales');
      } else if (user.department === 'parts') {
        setDepartment('Parts');
      } else if (user.department === 'service') {
        setDepartment('Service');
      }
    }
  }, []);

  const calculateTotal = data => {
    return data.reduce((acc, row) => {
      const amounts = Object.values(row).filter(
        value => typeof value === 'number',
      );
      const rowTotal = amounts.reduce((sum, value) => sum + value, 0);
      return acc + rowTotal;
    }, 0);
  };
  const totalOfAllTotals = tripsData
    ? calculateTotal(Object.values(tripsData))
    : 0;

  const getMinDateForCalendar = (selectedDate = new Date()) => {
    setMinDate(date.addDays(selectedDate, -30));
  };

  const getMaxDateForCalendar = (selectedDate = new Date()) => {
    setMaxDate(date.addDays(selectedDate, 30));
  };

  const exportData = () => {
    const datesArray = [];
    for (
      let i = new Date(dateRange[0].getTime());
      i <= dateRange[1];
      i.setDate(i.getDate() + 1)
    ) {
      datesArray.push(date.format(new Date(i), 'DD-MM-YYYY'));
    }

    const headerRow = [
      {
        type: String,
        value: 'Name',
        fontWeight: 'bold',
      },
      ...datesArray.map(d => {
        return {
          type: String,
          value: d,
          fontWeight: 'bold',
          align: 'center',
        };
      }),
      {
        type: String,
        value: 'Total',
        align: 'right',
        fontWeight: 'bold',
      },
    ];

    const dataRows = Object.values(tripsData)
      .sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      })
      .map((employee, i) => {
        const row = [
          {
            type: String,
            value: employee.name,
          },
        ];
        datesArray.forEach(d => {
          if (employee[d]) {
            row.push({
              type: Number,
              value: employee[d],
            });
          } else {
            row.push({
              type: Number,
              value: 0,
            });
          }
        });
        row.push({
          type: 'Formula',
          value: `SUM(B${i + 2}:${getExcelColumnName(datesArray.length)}${
            i + 2
          })`,
          fontWeight: 'bold',
        });
        return row;
      });

    const lastRow = [
      {
        type: String,
        value: 'Total',
        fontWeight: 'bold',
        align: 'center',
        span: datesArray.length + 1,
      },
      ...datesArray.map(() => null),
      {
        type: 'Formula',
        value: `SUM(${getExcelColumnName(
          datesArray.length + 1,
        )}2:${getExcelColumnName(datesArray.length + 1)}${
          dataRows.length + 1
        })`,
        fontWeight: 'bold',
      },
    ];
    dataRows.push();

    const data = [headerRow, ...dataRows, lastRow];

    return writeXlsxFile(data, {
      stickyRowsCount: 1,
      stickyColumnsCount: 1,
      columns: [
        {width: 25},
        ...datesArray.map(() => {
          return {width: 12};
        }),
        {width: 12},
      ],
      fileName: `${datesArray[0]}_${
        datesArray[datesArray.length - 1]
      }_${type}_${department}.xlsx`,
    });
  };

  const fetchData = async () => {
    setIsFetchingData(true);
    const departments = [];
    if (department === 'all') {
      departments.push('Sales', 'Parts', 'Service');
    } else {
      departments.push(department);
    }

    const statuses = [];
    if (type === 'due') {
      if (organization.settings.isModeratorEnabled) {
        statuses.push('approved_by_moderator');
      } else {
        statuses.push('approved');
      }
    } else if (type === 'paid') {
      statuses.push('paid');
    } else {
      statuses.push('approved', 'approved_by_moderator', 'paid');
    }

    const tripsData = await getTripsData(
      dateRange,
      user.organizationId,
      departments,
      statuses,
    );

    if (Object.keys(tripsData).length === 0) {
      setIsNoTripsModalOpen(true);
      setIsFetchingData(false);
      return;
    }

    setTripsData(tripsData);
    setIsFetchingData(false);
    return;
  };

  return (
    <View>
      <VStack w="full" space="6" maxH="90vh">
        <VStack space="6" zIndex="2">
          <HStack space="4">
            <DateRangePicker
              onChange={setDateRange}
              value={dateRange}
              minDate={minDate}
              maxDate={maxDate}
              onClickDay={v => {
                getMinDateForCalendar(v);
                getMaxDateForCalendar(v);
              }}
              onCalendarOpen={() => {
                setMinDate(DEFAULT_MIN_DATE);
                setMaxDate(DEFAULT_MAX_DATE);
              }}
              format="dd/MM/yyyy"
              selectRange={true}
              clearIcon={null}
            />
            <Select
              isDisabled={!(user.isAdmin || user.isFinance || user.isModerator)}
              selectedValue={department}
              placeholder="Department"
              size="lg"
              w="150px"
              onValueChange={setDepartment}>
              <Select.Item label="All" value="all" />
              <Select.Item label="Sales" value="Sales" />
              <Select.Item label="Parts" value="Parts" />
              <Select.Item label="Service" value="Service" />
            </Select>
            <Select
              placeholder="Trips status"
              size="lg"
              w="100px"
              onValueChange={setType}>
              <Select.Item label="All" value="all" />
              <Select.Item label="Due" value="due" />
              <Select.Item label="Paid" value="paid" />
            </Select>
            <HStack space="4">
              <Button
                onPress={fetchData}
                isLoading={isFetchingData}
                isDisabled={!department || !type}>
                <Text fontWeight="bold" color="white">
                  Get Data
                </Text>
              </Button>

              {tripsData && (
                <Button
                  onPress={exportData}
                  colorScheme="secondary"
                  startIcon={<FaFileExcel color="white" size="20" />}>
                  <Text fontWeight="bold" color="white">
                    Export
                  </Text>
                </Button>
              )}
            </HStack>
          </HStack>
        </VStack>

        <VStack w="full" flex="1">
          <MaterialReactTable table={table} />
        </VStack>
      </VStack>

      <AlertDialog
        useRNModal={true}
        isOpen={isNoTripsModalOpen}
        onClose={() => setIsNoTripsModalOpen(false)}>
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>No Trips</AlertDialog.Header>
          <AlertDialog.Body>
            <Text>
              No {department === 'all' ? '' : department} trips are present in
              given date range with status {type}.
            </Text>
          </AlertDialog.Body>
          <HStack w="full" justifyContent="flex-end" p="4" pt="0">
            <Button onPress={() => setIsNoTripsModalOpen(false)} minW="24">
              Okay
            </Button>
          </HStack>
        </AlertDialog.Content>
      </AlertDialog>
    </View>
  );
}
