import {useState, useEffect} from 'react';
import {Button, Heading, HStack, View, VStack} from 'native-base';
import Financial from '../components/reports/Financial';
import Services from '../components/reports/Services';
import Leads from '../components/reports/Leads';
import {useUser} from '../contexts/userContext';

export default function Reports() {
  const [tab, setTab] = useState('Financial');
  const [allTabs, setAllTabs] = useState(['Financial']);
  const [user] = useUser();

  useEffect(() => {
    if (user.isAdmin || user.department === 'service') {
      setAllTabs(['Financial', 'Services', 'Leads']);
    } else if (['sales', 'parts'].includes(user.department)) {
      setAllTabs(['Financial', 'Leads']);
    } else {
      setAllTabs(['Financial']);
    }
  }, []);

  return (
    <View>
      <VStack p="8" pb="0" w="full" space="6" maxH="90vh">
        <Heading>Reports</Heading>

        <HStack borderBottomWidth="1" borderColor="coolGray.300">
          {allTabs.map((t, i) => (
            <Button
              variant={(!tab && t === 'All') || tab === t ? 'solid' : 'ghost'}
              onPress={() => setTab(t)}
              borderRadius="0"
              minWidth="84px"
              key={i}>
              {t}
            </Button>
          ))}
        </HStack>

        {tab === 'Financial' && <Financial />}
        {tab === 'Services' && <Services />}
        {tab === 'Leads' && <Leads />}
      </VStack>
    </View>
  );
}
